<script setup lang="ts">
import { ButtonProps } from "~/components/Utils/Button.props"
import { SuggestionsProps } from "~/components/Search/Utils/Suggestions.props"
import { mapProducts, mapProductsAlgolia } from "~/utils/mapProducts"
import { customPaths } from "~/utils/constants"
import { addSearchTermIntoCookie } from "~/utils/cookies/searchCookies"
import { NotificationAlertProps } from "~/components/NotificationAlert.props"
import {
  AisInstantSearch,
  AisConfigure,
  AisHits,
  AisIndex,
  AisSearchBox,
  AisStateResults
  // @ts-ignore
} from "vue-instantsearch/vue3/es/index.js"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { hitsViewed } from "~/utils/algolia"
import { PRODUCTS_VIEWED_SEARCHBAR } from "~/config/algolia-insights"
import { excludeOutOfMarketRuleContext } from "~/utils/algolia"
import { useResizeObserver } from "@vueuse/core"
import {
  getSearchMethod,
  searchAction,
  searchMethod,
  setSearchMethod,
  debounceTimeout
} from "~/utils/analytics/search"

const { sendEventViewSearchResults } = useGA4SiteEvents()

const { t } = useI18n()
const { notifications, addNotification, removeNotification } = useNotification()
const lastQueries = useStatefulCookie("lastQueries", {
  maxAge: 2592000,
  default: () => []
})
const { scrollLock, scrollUnlock } = useScrollLocker()
const route = useRoute()

// Suggestions
type Suggestion = {
  query: string
}

const indexName = ref(`TOP_PRODUCTS${checkEnvSite}`)
const indexSuggestions = ref(`TOP_PRODUCTS${checkEnvSite}_query_suggestions`)

const productsSearched = ref()

const searchClient = {
  ...useAlgoliaRef(),
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: ""
        }))
      })
    }

    const response = useAlgoliaRef().search(requests)

    response.then((results) => {
      const data = results.results[0]
      sendEventViewSearchResults(getSearchMethod(), data.query, data.nbHits)
      setSearchMethod(searchMethod.query)
    })
    return response
  }
}

const debounceEventsAlgolia = useDebounceFn(async () => {
  if (productsSearched.value && productsSearched.value.length > 0) {
    hitsViewed(
      "viewedObjectIDs",
      indexName.value,
      PRODUCTS_VIEWED_SEARCHBAR,
      productsSearched.value
    )
  }
}, 1500)

const searchFunction = async (helper: any) => {
  if (helper.state.query && helper.state.query.length < 3) {
    return
  }
  debounceEventsAlgolia()
  const data = helper.setQueryParameter("clickAnalytics", true).search()
}

const emit = defineEmits<{ (e: "onClose"): void }>()

const handleClose = () => {
  document.activeElement?.blur()
  notifications.value.splice(0, notifications.value.length)
  scrollUnlock()
  switchState(false)
}

const { isOpen, switchState } = useUIState("focus-search")
const { switchState: hasAddedFromAlgoliaSearchBar } = useUIState(
  "added-from-algolia-searchbar"
)

const queryState = useState("searchbar-query-state", () => "")

const showSearchPopup = () => {
  scrollLock()
  switchState(true)
}

// Get popular items
const { data: popularItems } = await useFetch(
  "/api/algolia/getPopularItems/6",
  {
    transform: (res) => mapProducts(res.hits, res.queryID, "ALGOLIA")
  }
)

const goToCart = () => {
  navigateTo(customPaths.cart)
}

const handleAddToCartNotification = (productName: string) => {
  hasAddedFromAlgoliaSearchBar(true)

  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t(
      "dialogsAndModals.addToCart.notificationAddToCartShort"
    ),
    ctaFunction: goToCart,
    cta: t("dialogsAndModals.addToCart.notificationLinkToCart"),
    ctaInline: true,
    isWithCloseIcon: true,
    small: true
  }

  addNotification(notification)
}

/**
 * GTM Search Events
 */
const { event, sendEvent } = useGAEvent(
  `track_view_search_results`,
  "custom_event"
)
/**
 * @description On clicking on the search cta
 * @param {string} searchTerm
 * @param {number} searchResultsNumber
 */
const ga4EventViewSearchResults = (
  searchTerm: string,
  searchResultsNumber: number
) => {
  event.value = {
    event: "view_search_results",
    action: "view_search_results",
    search_term: searchTerm,
    search_results_number: searchResultsNumber
  }

  lastQueries.value = addSearchTermIntoCookie(lastQueries.value, searchTerm)
  sendEvent({ clearEcommerce: false })
}

const viewResults = (searchTerm: string, searchResultsNumber: number) => {
  ga4EventViewSearchResults(searchTerm, searchResultsNumber)
  handleClose()
}

const handleBrandSuggestion = (text: string) => {
  sendEventViewSearchResults(
    searchMethod.suggestions,
    text,
    null,
    searchAction.brands
  )
}

const searchbarModal = ref(null)

const isClosable = ref<boolean>(false)

onKeyStroke(
  "Escape",
  (e) => {
    if (isClosable.value) handleClose()
  },
  { dedupe: false }
)

watch(isOpen, (value) => {
  if (value && !isClosable.value) {
    useTimeoutFn(() => {
      isClosable.value = true
    }, 300)
  } else {
    isClosable.value = false
  }
})

const localQuery = ref()
const timerId = ref()

const debounceRefine = (val: string | undefined) => {
  localQuery.value = val
  if (timerId.value) {
    clearTimeout(timerId.value)
  }
  if (localQuery.value != algoliaSearchbarQueryState.value) {
    timerId.value = setTimeout(() => {
      algoliaSearchbarQueryState.value = localQuery.value
    }, debounceTimeout)
  }
}

const lastSearch = computed<SuggestionsProps["suggestions"]>(() =>
  lastQueries.value.length > 0
    ? lastQueries.value
        .map((query: string) => ({
          text: query.charAt(0).toUpperCase() + query.slice(1)
        }))
        .slice(0, 3)
    : []
)

const querySuggestions = computedAsync(async () =>
  useLazyFetch(`/api/algolia/getQuerySuggestions`, { server: false }).then(
    ({ data: topQueries }) => {
      return topQueries.value?.hits?.length
        ? topQueries.value?.hits?.slice(0, 3).map((hit) => ({
            text: hit.query.charAt(0).toUpperCase() + hit.query.slice(1)
          }))
        : []
    }
  )
)

const brandSuggestions = computedAsync(async () =>
  useLazyFetch(`/api/algolia/getBrandSuggestions`, { server: false }).then(
    ({ data: topBrands }) => {
      return topBrands.value?.hits?.length
        ? topBrands.value?.hits?.map((hit) => {
            return {
              text: hit.brandName,
              isSelectable: false,
              path: `/b-${hit.slug}`
            }
          })
        : []
    }
  )
)

const algoliaSearchbarQueryState = useState("searchbar-query-state")
const applyQuery = (text: string, method: string, action: string) => {
  setSearchMethod(method, action)
  algoliaSearchbarQueryState.value = text
  lastQueries.value = addSearchTermIntoCookie(lastQueries.value, text)
}

const transformSuggestions = (suggestions: Suggestion[]): ButtonProps[] => {
  return suggestions.map((suggestions) => ({
    text: suggestions.query,
    isSelectable: false
  }))
}

const overlayRef = ref(null)
const overlayHeight = ref(0)
useResizeObserver(overlayRef, (entries) => {
  const entry = entries[0]
  const { width, height } = entry.contentRect
  overlayHeight.value = height
})

const disabled = ref(true)
onMounted(() => {
  disabled.value = false
})

watch(
  () => route.fullPath,
  () => {
    handleClose()
  }
)
</script>

<template>
  <div
    @keyup.enter="handleClose"
    class="
      search-searchbar_focused
      customHeight
      w-full
      overflow-y-auto
      overscroll-none
      md:h-auto
      md:overscroll-auto
    "
  >
    <div
      class="
        wrapper-notifications
        padded
        fixed
        left-4
        right-4
        top-[160px]
        z-[999999]
        flex flex-col
        gap-2
        p-0
        md:right-[24px]
        md:top-[38px]
        md:gap-4
      "
      v-if="isOpen"
    >
      <TransitionGroup name="opacity">
        <NotificationAlert
          class="flex justify-end"
          v-for="notification in notifications"
          :key="notification.id"
          v-bind="notification"
          @on-close="removeNotification(notification.id)"
        />
      </TransitionGroup>
    </div>

    <AisInstantSearch
      :index-name="indexName"
      :search-client="searchClient"
      :search-function="searchFunction"
      class="h-full md:h-auto"
    >
      <AisConfigure
        :hits-per-page.camel="6"
        :ruleContexts="excludeOutOfMarketRuleContext"
        :query="algoliaSearchbarQueryState"
      />
      <div ref="searchbarModal">
        <div class="flex !h-fit flex-col md:block">
          <div
            class="
              flex
              h-[60px]
              shrink-0
              items-center
              gap-1
              bg-white
              shadow-01
              md:relative
              md:h-auto
            "
          >
            <Transition name="opacity">
              <UtilsButton
                theme="ghost-black"
                v-show="isOpen"
                class="md:hidden"
                @click="handleClose"
              >
                <template #icon>
                  <UtilsIcon name="ArrowLeft.svg" class="h-6 w-6" />
                </template>
              </UtilsButton>
            </Transition>
            <div class="flex-1">
              <SearchSearchbar
                v-bind="{
                  name: 'main-search',
                  label: 'Search label',
                  placeholder: $t('searchbar.placeholder'),
                  isPlaceholderAnimated: false,
                  disabled: disabled,
                  showTextIcon: false
                }"
                :trigger-focus="isOpen"
                @on-focus-in="showSearchPopup"
                @update:model-value="(val) => debounceRefine(val)"
                :class="`relative z-40`"
                :is-open="isOpen"
                :forceQuery="queryState"
              />
              <Transition name="opacity">
                <div
                  v-if="isOpen"
                  class="
                    fixed
                    left-0
                    top-0
                    z-30
                    hidden
                    h-screen
                    w-full
                    bg-black-main/30
                    md:block
                  "
                  @click="handleClose()"
                />
              </Transition>
              <AisSearchBox hidden></AisSearchBox>
            </div>
          </div>
        </div>
        <Transition name="opacity">
          <div
            v-show="isOpen"
            class="
              fixed
              left-0
              z-40
              h-screen
              max-h-screen
              w-full
              overflow-y-auto
              bg-white
              pb-[60px]
              md:absolute
              md:left-auto
              md:mr-2
              md:mt-1
              md:h-auto
              md:w-auto
              md:max-w-[840px]
              md:rounded-[16px]
              md:pb-0
            "
          >
            <AisStateResults>
              <template
                v-slot="{ state: { query }, results: { hits, nbHits }, status }"
              >
                <div
                  class="relative overflow-y-auto transition-all"
                  :key="queryState"
                  ref="overlayRef"
                >
                  <div
                    :class="`absolute left-0 top-0 z-50 h-full w-full bg-white`"
                    v-if="status == 'loading' || status == 'stalled'"
                  >
                    <UtilsLoader
                      dimensions="big"
                      type="spinner"
                      class="justify-center align-middle"
                    />
                  </div>
                  <div
                    :class="{
                      'px-4 md:grid md:grid-cols-4 md:gap-4 md:px-6': true,
                      'md:min-h-[67px]': nbHits != 1
                    }"
                  >
                    <SearchUtilsSuggestions
                      v-if="queryState.length <= 2 && lastSearch?.length"
                      class="col-span-1 pb-4 md:pb-0"
                      :title="t('searchbar.labelLastSearchs')"
                      :suggestions="lastSearch"
                      @click="
                        applyQuery(
                          $event,
                          searchMethod.recent,
                          searchAction.symptoms
                        )
                      "
                    />
                    <SearchUtilsSuggestions
                      v-if="queryState.length <= 2 && querySuggestions?.length"
                      class="col-span-1 pb-4 md:pb-0"
                      :title="t('searchbar.popularSearch')"
                      :suggestions="querySuggestions"
                      @click="
                        applyQuery(
                          $event,
                          searchMethod.popular,
                          searchAction.symptoms
                        )
                      "
                    />
                    <SearchUtilsSuggestions
                      v-if="queryState.length <= 2 && brandSuggestions?.length"
                      class="col-span-1"
                      :title="t('searchbar.popularBrands')"
                      :suggestions="brandSuggestions"
                      @click="handleBrandSuggestion($event)"
                    />
                    <AisIndex
                      :index-name="indexSuggestions"
                      v-if="queryState.length > 2 && nbHits > 0"
                      class="col-span-2"
                    >
                      <AisConfigure
                        :hits-per-page.camel="3"
                        :query="algoliaSearchbarQueryState"
                      />
                      <AisHits :transform-items="transformSuggestions">
                        <template v-slot="{ items: suggestions }">
                          <SearchUtilsSuggestions
                            v-if="suggestions.length"
                            :title="t('searchbar.suggestions')"
                            :suggestions="suggestions"
                            @click="
                              applyQuery(
                                $event,
                                searchMethod.suggestions,
                                searchAction.symptoms
                              )
                            "
                          />
                        </template>
                      </AisHits>
                    </AisIndex>
                    <SearchUtilsNoResults
                      class="col-span-2 pt-4 md:pt-6"
                      :searchTerm="queryState"
                      :cta="customPaths.requestNewProduct"
                      :ctaText="t('searchbar.requestProduct')"
                      v-if="queryState.length > 2 && nbHits == 0"
                    />
                  </div>
                  <div :class="['grid-search p-4 md:p-6']">
                    <AisHits
                      :transform-items="mapProductsAlgolia"
                      class="col-span-full"
                      v-if="queryState.length > 2 && hits.length > 0"
                    >
                      <template v-slot="{ items }">
                        <SearchUtilsProducts
                          class="col-span-full"
                          v-bind="{
                            title: $t('searchbar.products'),
                            cards: items,
                            moreProducts: {
                              text:
                                nbHits > 1
                                  ? t('searchbar.showAllResultsN', nbHits)
                                  : t('searchbar.showAllResults'),
                              path: `${customPaths.search}?query=${queryState}`
                            }
                          }"
                          @on-add-to-cart="handleAddToCartNotification($event)"
                          @on-click-show-result="
                            viewResults(queryState, nbHits)
                          "
                        />
                      </template>
                    </AisHits>
                    <SearchUtilsProducts
                      v-else-if="queryState.length > 2"
                      class="col-span-full"
                      v-bind="{
                        title: $t('searchbar.moreSold'),
                        cards: popularItems,
                        moreProducts: {
                          text: t('searchbar.showMostSold'),
                          path: `${customPaths.mostSold}`
                        }
                      }"
                    />
                    <SearchUtilsProducts
                      v-else
                      class="col-span-full"
                      v-bind="{
                        title: $t('searchbar.moreSold'),
                        cards: popularItems
                      }"
                    />
                  </div>
                </div>
              </template>
            </AisStateResults>
          </div>
        </Transition>
      </div>
    </AisInstantSearch>
  </div>
</template>

<style lang="scss" scoped>

.search-searchbar_focused {
  :deep(.ais-Stats, .ais-InstantSearch) {
    @apply h-full;
  }
}

</style>
