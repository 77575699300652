<script setup lang="ts">
import { AddToCartProps } from "./AddToCart.props"
import { customPaths } from "~/utils/constants"
import { vOnClickOutside } from "@vueuse/components"

withDefaults(defineProps<AddToCartProps>(), {
  showPointer: true
})

const { switchState } = useUIState("focus-search")

const emit =
  defineEmits<{
    (e: "closePopup"): void
  }>()

const handleClosePopup = () => {
  switchState(false)
  emit("closePopup")
}
</script>

<template>
  <div
    v-on-click-outside="handleClosePopup"
    class="
      add-to-cart-popup
      relative
      rounded-lg
      bg-white
      px-2
      pb-4
      pt-2
      shadow-02
      md:p-4
    "
  >
    <div
      class="
        mb-4
        flex
        items-center
        justify-between
        border-b border-grey-100
        pb-2
      "
    >
      <p class="mouse">{{ $t("dialogsAndModals.addToCart.title") }}</p>
      <UtilsIcon
        name="Close.svg"
        class="h-6 w-6 cursor-pointer"
        @click="handleClosePopup"
      />
    </div>

    <div v-if="showPointer" class="triangle-custom hidden md:block"></div>

    <ProductTileOverallSearch no-button v-bind="productAdded" class="mb-4" />

    <!-- * While this has to be removed as of right now, it's most likely gonna be a requirement in the future -->
    <!-- <p
      v-if="productAdded?.quantity && productAdded?.quantity > 1"
      class="mouse mb-4 text-black-80"
    >
      <UtilsMarkdown
        tag="span"
        :content="$t('dialogsAndModals.addToCart.message')"
      />
    </p> -->

    <div class="flex items-center justify-between">
      <UtilsButton
        @click="handleClosePopup"
        theme="outlined-green"
        :text="$t('dialogsAndModals.addToCart.seeCart')"
        fluid
        class="mr-4 [&_span.utils-markdown]:text-center"
        :to="customPaths.cart"
      />
      <UtilsButton
        :text="$t('dialogsAndModals.addToCart.goToPay')"
        fluid
        class="[&_span.utils-markdown]:text-center"
        :to="customPaths.checkout"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

:deep(.btn__container) {
  p {
    @apply mouse-medium;
  }
}

.triangle-custom {
  position: absolute;
  top: -8px;
  right: 10px;
  z-index: -1;
  background-color: #fff;
  text-align: left;
}
.triangle-custom:before,
.triangle-custom:after {
  content: "";
  position: absolute;
  background-color: white;
}
.triangle-custom,
.triangle-custom:before,
.triangle-custom:after {
  width: 16px;
  height: 16px;
  border-top-right-radius: 30%;
}

.triangle-custom {
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
}

</style>
